import { TooltipCallbacks } from 'chart.js'
import { format } from 'date-fns'
import { t } from 'i18next'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookType } from 'xlsx'

import { Card, CardContent, CardHeader, Divider, FormControl, SelectChangeEvent } from '@mui/material'

import { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import { resolveDataset } from '../../../../components/PerformanceChartV2/PerformanceChartV2'
import { PerformanceChartV2DataType } from '../../../../components/PerformanceChartV2/PerformanceChartV2DataType'
import { PerformanceChartV2Filters } from '../../../../components/PerformanceChartV2/PerformanceChartV2Filters'
import { YAxisConfig } from '../../../../components/PerformanceChartV2/PerformanceChartV2Types'
import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu'
import SideBySideCharts from '../../../../components/SideBySideCharts/SideBySideCharts'
import { ScaleConfig, TimeScaleConfig } from '../../../../components/TimelineChart/TimelineChart'
import { valueIsBetween } from '../../../../helpers/valueIsBetween'
import { useExportDataComparePerformanceChart } from '../../../../hooks/exportDataHooks'
import utilsService from '../../../../services/UtilsService'
import { GameEstimates, MarketsPerformanceEstimates } from '../../../estimates'
import { generateExport } from '../../../export-data/util/workbook'
import { GameAndAnalysis } from '../../../game/types/GameAndAnalysis'
import { resolveTimeunitByGranularity } from '../../../revenue-and-downloads/helpers/helpers'
import { GranularityValue } from '../../../revenue-and-downloads/types/Filters'
import { ChartDataFormat } from '../../../update-history/types/types'
import { TableRowUpdateImpact } from '../../../update-impacts/types/TableRowUpdateImpact'
import { useCompareGamesPerformanceChartsData } from '../../hooks/useCompareGamesPerformanceChartsData'

export enum ComparePerformanceDataType {
  Revenue,
  Download,
  RevenueDownloadsRatioAllTime,
  RevenueDownloadsRatio7Days,
  RevenueDownloadsRatio30Days,
  RevenueDownloadsRatio90Days,
}

const rollingDaysByDataType = {
  [PerformanceChartV2DataType.Revenue]: 1,
  [PerformanceChartV2DataType.Downloads]: 1,
  [PerformanceChartV2DataType.RevenueDownloadsRatio7Days]: 7,
  [PerformanceChartV2DataType.RevenueDownloadsRatio30Days]: 30,
  [PerformanceChartV2DataType.RevenueDownloadsRatio90Days]: 90,
  [PerformanceChartV2DataType.RevenueDownloadsRatioAllTime]: 0,
  [PerformanceChartV2DataType.Ranks]: 1,
  [PerformanceChartV2DataType.DAU]: 1,
  [PerformanceChartV2DataType.MAU]: 1,
  [PerformanceChartV2DataType.ARPDAU]: 1,
}

type ResolveYAxisConfigParams = {
  axisConfigs: { data: ReturnType<typeof useCompareGamesPerformanceChartsData>; axisConfig: YAxisConfig }[]
  dateRange?: DateRangeValue
  granularity: GranularityValue
}

const datasetColors = utilsService.getRGBChartColorList()

type DataTypeSelectorProps = {
  onChange: (event: SelectChangeEvent<ComparePerformanceDataType>) => void
  value: ComparePerformanceDataType | ''
}
export const DataTypeSelector: FC<DataTypeSelectorProps> = ({ onChange, value }) => {
  const { t } = useTranslation()
  const options = useMemo(() => {
    return [
      {
        name: `${t('common:revenue_text')} (${t('common:apple_ios')})`,
        value: ComparePerformanceDataType.Revenue,
      },
      {
        name: `${t('common:downloads_text')} (${t('common:apple_ios')})`,
        value: ComparePerformanceDataType.Download,
      },
      {
        name: `${t('common:all_time_revenue_downloads_ratio')} (${t('common:apple_ios')})`,
        value: ComparePerformanceDataType.RevenueDownloadsRatioAllTime,
      },
      {
        name: `${t('common:revenue_downloads_ratio_days', { days: 7 })} (${t('common:apple_ios')})`,
        value: ComparePerformanceDataType.RevenueDownloadsRatio7Days,
      },
      {
        name: `${t('common:revenue_downloads_ratio_days', { days: 30 })} (${t('common:apple_ios')})`,
        value: ComparePerformanceDataType.RevenueDownloadsRatio30Days,
      },
      {
        name: `${t('common:revenue_downloads_ratio_days', { days: 90 })} (${t('common:apple_ios')})`,
        value: ComparePerformanceDataType.RevenueDownloadsRatio90Days,
      },
    ]
  }, [t])

  return (
    <FormControl size="small" variant="outlined" sx={{ minWidth: 310 }}>
      <SelectMenu label={t('common:data')} options={options} onChange={onChange} value={value} />
    </FormControl>
  )
}

interface MotivationPerformanceChartProps {
  gamesAndAnalysis: GameAndAnalysis[]
  estimates?: MarketsPerformanceEstimates
  allGameEstimates?: GameEstimates[]
  dateRange?: DateRangeValue
  onDateRangeChanged?: (dateRange: DateRangeValue | undefined) => void
  updateImpactsMappedForTable?: TableRowUpdateImpact[]
  handleOpenUpdateClicked: (update: TableRowUpdateImpact) => void
  yAxisRightConfig: YAxisConfig
  yAxisLeftConfig: YAxisConfig
  onYAxisLeftConfigChanged: (value: YAxisConfig) => void
  onYAxisRightConfigChanged: (value: YAxisConfig) => void
  onGranularityChanged?: (value: GranularityValue) => void
  granularity?: GranularityValue
  minDate: Date
}

const ComparePerformanceChart: React.FC<MotivationPerformanceChartProps> = ({
  gamesAndAnalysis,
  allGameEstimates,
  dateRange,
  onDateRangeChanged,
  updateImpactsMappedForTable = [],
  handleOpenUpdateClicked,
  yAxisRightConfig,
  yAxisLeftConfig,
  onYAxisLeftConfigChanged,
  onYAxisRightConfigChanged,
  onGranularityChanged,
  granularity = GranularityValue.Day,
  minDate,
}) => {
  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)

  const gameEstimatesLeftChart = useCompareGamesPerformanceChartsData({
    gamesAndAnalysis,
    rollingDays: rollingDaysByDataType[yAxisLeftConfig.dataType],
    granularity,
  })
  const gameEstimatesRightChart = useCompareGamesPerformanceChartsData({
    gamesAndAnalysis,
    rollingDays: rollingDaysByDataType[yAxisRightConfig.dataType],
    granularity,
  })

  const versionMarks = useMemo(() => {
    const highlightedVersionMarks: { position: number; data: TableRowUpdateImpact }[] = []
    const normalVersionMarks: { position: number; data: TableRowUpdateImpact }[] = []

    updateImpactsMappedForTable
      .map((update) => ({ position: update.releaseDate, data: update }))
      .filter((mark) => valueIsBetween(mark.position, dateRange?.fromDate?.getTime(), dateRange?.toDate?.getTime()))
      .forEach((mark) => {
        if (!!mark.data.changedFeatures.length) {
          highlightedVersionMarks.push(mark)
        } else {
          normalVersionMarks.push(mark)
        }
      })

    return { highlightedVersionMarks, normalVersionMarks }
  }, [dateRange?.fromDate, dateRange?.toDate, updateImpactsMappedForTable])

  const useYAxisConfig = ({ axisConfigs, dateRange, granularity }: ResolveYAxisConfigParams) => {
    const { t } = useTranslation()

    return {
      datasets: axisConfigs.flatMap((axisConfig, index) => {
        const {
          data,
          axisConfig: { dataType },
        } = axisConfig
        const axisId = `y1`

        return data.map((gameEstimate, index) => {
          const color = datasetColors[index]
          const gameLabel = `${gameEstimate.game.resolvedName} (${gameEstimate.marketIso?.toUpperCase()})`
          switch (dataType) {
            default:
            case PerformanceChartV2DataType.Revenue:
              return resolveDataset(
                axisId,
                gameLabel,
                color,
                ChartDataFormat.Currency,
                gameEstimate.estimates.map((estimate) => ({
                  x: estimate.ts,
                  y: estimate.revenue || 0,
                })),
                dateRange
              )
            case PerformanceChartV2DataType.Downloads:
              return resolveDataset(
                axisId,
                gameLabel,
                color,
                ChartDataFormat.Number,
                gameEstimate.estimates.map((estimate) => ({
                  x: estimate.ts,
                  y: estimate.downloads || 0,
                })),
                dateRange
              )
            case PerformanceChartV2DataType.RevenueDownloadsRatio7Days:
            case PerformanceChartV2DataType.RevenueDownloadsRatio30Days:
            case PerformanceChartV2DataType.RevenueDownloadsRatio90Days:
              return resolveDataset(
                axisId,
                gameLabel,
                color,
                ChartDataFormat.Currency,
                gameEstimate.estimates?.map((estimate) => ({
                  x: estimate.ts,
                  y: estimate.revenueAndDownloadsRatio || 0,
                })),
                dateRange
              )
            case PerformanceChartV2DataType.RevenueDownloadsRatioAllTime:
              return resolveDataset(
                axisId,
                gameLabel,
                color,
                ChartDataFormat.Currency,
                gameEstimate.estimates?.map((estimate) => ({
                  x: estimate.ts,
                  y: estimate.revenueAndDownloadsRatio || 0,
                })),
                dateRange
              )
          }
        })
      }),
      scaleConfig: {
        x: {
          time: {
            unit: resolveTimeunitByGranularity(granularity),
            isoWeekday: true,
          },
        } as TimeScaleConfig,
        ...axisConfigs.reduce((acc, axisConfig, index) => {
          const {
            axisConfig: { dataType },
          } = axisConfig
          const axisId = `y${index + 1}`
          const rollingDays = rollingDaysByDataType[dataType]
          switch (dataType) {
            case PerformanceChartV2DataType.Revenue:
              acc[axisId] = {
                dataFormat: ChartDataFormat.Currency,
                title: `${t('common:revenue_text')} (${t('common:apple_ios')})`,
                shorten: true,
              }

              return acc
            case PerformanceChartV2DataType.Downloads:
              acc[axisId] = {
                dataFormat: ChartDataFormat.Number,
                title: `${t('common:downloads_text')} (${t('common:apple_ios')})`,
                shorten: true,
              }

              return acc
            case PerformanceChartV2DataType.RevenueDownloadsRatio7Days:
            case PerformanceChartV2DataType.RevenueDownloadsRatio30Days:
            case PerformanceChartV2DataType.RevenueDownloadsRatio90Days:
              acc[axisId] = {
                dataFormat: ChartDataFormat.Currency,
                title: `${t('common:revenue_downloads_ratio_days', { days: rollingDays })} (${t('common:apple_ios')})`,
                shorten: true,
              }
              return acc
            case PerformanceChartV2DataType.RevenueDownloadsRatioAllTime:
              acc[axisId] = {
                dataFormat: ChartDataFormat.Currency,
                title: `${t('common:all_time_revenue_downloads_ratio')} (${t('common:apple_ios')})`,
                shorten: true,
              }

              return acc
            default:
              acc[axisId] = {}
              return acc
          }
        }, {} as { [axisdId: string]: ScaleConfig | {} }),
      },
    }
  }

  const chartConfigLeft = useYAxisConfig({
    axisConfigs: [{ data: gameEstimatesLeftChart, axisConfig: yAxisLeftConfig }],
    dateRange,
    granularity,
  })

  const chartConfigRight = useYAxisConfig({
    axisConfigs: [{ data: gameEstimatesRightChart, axisConfig: yAxisRightConfig }],
    dateRange,
    granularity,
  })

  const { exportRows, headerRows, filename } = useExportDataComparePerformanceChart(allGameEstimates, isExporting)

  useEffect(() => {
    if (!isExporting || !exportRows.length || !filename) return
    generateExport(exportFormat, exportRows, 'Compare Games Perfomance', filename, headerRows)
    setIsExporting(false)
  }, [isExporting, exportRows, headerRows, exportFormat, filename])

  const handleDataExport = (format: BookType) => {
    setExportFormat(format)
    setIsExporting(true)
  }

  const tooltipCallbacks = useMemo(() => {
    return {
      title: (tooltipItems) => {
        switch (granularity) {
          case GranularityValue.Day:
            return format(tooltipItems[0].parsed.x, 'EEE, LLL d, yyyy')
          case GranularityValue.Week:
            return format(tooltipItems[0].parsed.x, 'I/RRRR')
          case GranularityValue.Month:
            return format(tooltipItems[0].parsed.x, 'MMM, yyyy')
        }
      },
    } as Partial<TooltipCallbacks<'line'>>
  }, [granularity])

  return (
    <Card>
      <CardHeader title={t('common:performance')} />
      <Divider />
      <CardContent sx={{ pt: 2 }}>
        <PerformanceChartV2Filters
          dateRange={dateRange}
          granularity={granularity}
          yAxisLeftConfig={yAxisLeftConfig}
          yAxisRightConfig={yAxisRightConfig}
          onDateRangeChanged={onDateRangeChanged}
          onYAxisLeftConfigChanged={onYAxisLeftConfigChanged}
          onYAxisRightConfigChanged={onYAxisRightConfigChanged}
          onExportFormatSelected={handleDataExport}
          config={{ minDate: minDate.getTime() }}
          onGranularityChanged={onGranularityChanged}
        />

        <SideBySideCharts
          sharedHover={true}
          leftChartProps={{
            data: { datasets: chartConfigLeft.datasets },
            scaleConfig: chartConfigLeft.scaleConfig,
            verticalMarks: versionMarks.normalVersionMarks,
            onVerticalMarkClicked: handleOpenUpdateClicked,
            highlightedVerticalMarks: versionMarks.highlightedVersionMarks,
            tooltipCallbacks: tooltipCallbacks,
          }}
          rightChartProps={{
            data: { datasets: chartConfigRight.datasets },
            scaleConfig: chartConfigRight.scaleConfig,
            verticalMarks: versionMarks.normalVersionMarks,
            onVerticalMarkClicked: handleOpenUpdateClicked,
            highlightedVerticalMarks: versionMarks.highlightedVersionMarks,
            tooltipCallbacks: tooltipCallbacks,
          }}
        />
      </CardContent>
    </Card>
  )
}

export default ComparePerformanceChart
