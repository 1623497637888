import { startOfDay, startOfWeek, endOfWeek, endOfMonth, subWeeks, subMonths, isAfter } from 'date-fns'
import { useMemo } from 'react'

import { average } from '../../../helpers/average'
import { GranularityValue } from '../../revenue-and-downloads/types/Filters'

export const useAverageRevenuePerDailyActiveUser = ({
  averageRevenuePerDailyActiveUserItems,
  granularity,
}: {
  averageRevenuePerDailyActiveUserItems: {
    date: number
    value: any
  }[]
  granularity: GranularityValue
}) => {
  const now = new Date()
  let lastCompletedPeriodEnd: Date

  if (granularity === GranularityValue.Week) {
    lastCompletedPeriodEnd = endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 })
  } else if (granularity === GranularityValue.Month) {
    lastCompletedPeriodEnd = endOfMonth(subMonths(now, 1))
  } else {
    lastCompletedPeriodEnd = now
  }

  // Group revenue data by granularity
  const groupedRevenueData = useMemo(() => {
    const grouped = averageRevenuePerDailyActiveUserItems.reduce((acc, data) => {
      const revenueDate = new Date(data.date)

      // Group dates based on granularity
      let groupDate: number
      if (granularity === GranularityValue.Week) {
        groupDate = startOfWeek(revenueDate, { weekStartsOn: 1 }).getTime()
      } else if (granularity === GranularityValue.Month) {
        groupDate = startOfDay(new Date(revenueDate.getFullYear(), revenueDate.getMonth(), 1)).getTime()
      } else {
        groupDate = startOfDay(revenueDate).getTime()
      }

      // Only include data up to the last completed period
      if (isAfter(lastCompletedPeriodEnd, revenueDate)) {
        if (!acc[groupDate]) {
          acc[groupDate] = []
        }
        acc[groupDate].push(data.value)
      }

      return acc
    }, {} as { [groupDate: number]: number[] })

    // Calculate the average revenue for each group
    return Object.entries(grouped).map(([date, values]) => ({
      date: +date,
      value: average(values),
    }))
  }, [averageRevenuePerDailyActiveUserItems, granularity, lastCompletedPeriodEnd])

  return groupedRevenueData
}
