import { FC, useRef } from 'react'
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline'
import { Trans } from 'react-i18next'

import { Typography } from '@mui/material'

import { GRTooltip } from '../../../../../../components/GRTooltip/GRTooltip'
import { AverageRevenuePerDailyActiveUserTimelineItem } from '../../../../types/LiveEvents'

export const LiveEventsCalendarAverageRevenuePerDailyActiveUser: FC<ReactCalendarItemRendererProps<AverageRevenuePerDailyActiveUserTimelineItem>> = ({
  item,
  itemContext,
  getItemProps,
}) => {
  const itemRef = useRef(null)

  return (
    <GRTooltip
      content={
        <>
          <Typography variant="body2" textAlign="center" mb={1}>
            <Trans i18nKey={`live-events:average_revenue_per_daily_active_user`} />
          </Typography>
          <Typography variant="body2" textAlign="center">
            ${item.value}
          </Typography>
        </>
      }
      severity="success"
      anchorRef={itemRef}
    >
      <div
        {...getItemProps({
          style: {
            background: 'transparent',
            color: item.value > 0 ? '#38a73e' : '#888',
            border: `1px solid transparent`,
            borderRadius: 10,
            cursor: 'default',
          },
        })}
        ref={itemRef}
      >
        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            position: 'relative',
            bottom: '1px',
            textAlign: 'center',
          }}
        >
          ${item.value}
        </div>
      </div>
    </GRTooltip>
  )
}
