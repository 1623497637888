import { isBefore, sub } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'

import { Divider } from '@mui/material'

import { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { PerformanceChartV2DataType } from '../../../../components/PerformanceChartV2/PerformanceChartV2DataType'
import { YAxisConfig } from '../../../../components/PerformanceChartV2/PerformanceChartV2Types'
import { useError } from '../../../../hooks/useError'
import { useGameEstimateData, useGameEstimates } from '../../../estimates'
import { EstimatePlatformType } from '../../../estimates/types/EstimatePlatformType'
import { GameWithMarketIso } from '../../../game-search/components/GameSearchDialog/GameSearchDialog'
import { GameAndAnalysis } from '../../../game/types/GameAndAnalysis'
import { GranularityValue } from '../../../revenue-and-downloads/types/Filters'
import { useMultipleGameUpdateHistory } from '../../../update-history/hooks/useGameUpdateHistory'
import { useOpenUpdateModal } from '../../hooks/useOpenUpdateModal'
import ComparePerformanceChart from '../ComparePerformanceChart/ComparePerformanceChart'
import ComparePerformanceTable from '../ComparePerformanceChart/ComparePerformanceTable'

/**
 * PerformanceTabView
 */
interface PerformanceTabViewProps {
  gamesWithMarketIso: GameWithMarketIso
  gamesAndAnalysis: GameAndAnalysis[]
}
const PerformanceTabView: React.FC<PerformanceTabViewProps> = ({ gamesWithMarketIso, gamesAndAnalysis }) => {
  const [dateRange, setDateRange] = useState<DateRangeValue>()

  const markets = useMemo(() => [...new Set(Object.values(gamesWithMarketIso).flat())], [gamesWithMarketIso])
  const appIds = useMemo(() => gamesAndAnalysis.map(({ game }) => game.appId), [gamesAndAnalysis])
  const { estimatesList, isFetching: isEstimateListFetching } = useGameEstimateData({ appIds, markets })

  const excludedDataTypes = [
    PerformanceChartV2DataType.DAU,
    PerformanceChartV2DataType.MAU,
    PerformanceChartV2DataType.Ranks,
    PerformanceChartV2DataType.ARPDAU,
  ]
  const [yAxisLeftConfig, setYAxisLeftConfig] = useState<YAxisConfig>({ dataType: PerformanceChartV2DataType.Revenue, excludedDataTypes })
  const [yAxisRightConfig, setYAxisRightConfig] = useState<YAxisConfig>({ dataType: PerformanceChartV2DataType.Downloads, excludedDataTypes })
  const [granularity, setGranularity] = useState(GranularityValue.Week)

  const estimateFilters = useMemo(() => {
    const defaultFilters = {
      platformTypes: [EstimatePlatformType.Phone, EstimatePlatformType.Tablet],
      marketIsos: markets,
    }
    return {
      ...defaultFilters,
      dateFrom: dateRange?.fromDate?.getTime(),
      dateTo: dateRange?.toDate?.getTime(),
    }
  }, [markets, dateRange?.fromDate, dateRange?.toDate])

  const games = useMemo(() => gamesAndAnalysis.map(({ game }) => game), [gamesAndAnalysis])

  const { tsMin, tsMax, gameEstimates, allGameEstimates } = useGameEstimates({ data: estimatesList, filters: estimateFilters, gamesWithMarketIso, games })
  const { handleOpenUpdateClicked } = useOpenUpdateModal(gameEstimates)
  const gameUpdateHistoryQuery = useMemo(
    () => (gameEstimates ? gameEstimates.map(({ game, marketIso }) => ({ marketIso, appId: game.appId })) : []),
    [gameEstimates]
  )
  const {
    updateImpactsMappedForTable,
    error,
    isLoading: isImpactLoading,
    isFetching: isImpactFetching,
  } = useMultipleGameUpdateHistory(
    gameUpdateHistoryQuery,
    gameEstimates?.map((estimate) => estimate.game)
  )
  useError({ error })

  const maxDate = useMemo(() => {
    return new Date(new Date(tsMax || Date.now()).setHours(0, 0, 0, 0))
  }, [tsMax])

  const minDate = useMemo(() => {
    return new Date(new Date(tsMin || Date.now()).setHours(0, 0, 0, 0))
  }, [tsMin])

  const subgenresMap = useMemo(() => {
    return gamesAndAnalysis.reduce((acc, { game }) => {
      return { ...acc, [game.conventionalSubgenreId]: true }
    }, {})
  }, [gamesAndAnalysis])

  const handleGranularityChange = (granularity: GranularityValue) => {
    setGranularity(granularity)
  }

  // resolve initial date range for data
  useEffect(() => {
    const targetInitialFromDate = sub((maxDate || new Date()).setHours(0, 0, 0, 0), { days: 92 })
    const initialFromDate = isBefore(minDate, targetInitialFromDate) ? targetInitialFromDate : minDate
    const initialToDate = maxDate || sub(new Date().setHours(0, 0, 0, 0), { days: 2 })

    setDateRange({ fromDate: initialFromDate, toDate: initialToDate })
  }, [minDate, maxDate])

  if (isEstimateListFetching) {
    return <GRCircularProgress />
  }

  return (
    <>
      <ComparePerformanceChart
        gamesAndAnalysis={gamesAndAnalysis}
        dateRange={dateRange}
        onDateRangeChanged={(dateRange) => {
          setDateRange(dateRange)
        }}
        allGameEstimates={allGameEstimates}
        updateImpactsMappedForTable={updateImpactsMappedForTable}
        handleOpenUpdateClicked={handleOpenUpdateClicked}
        yAxisRightConfig={yAxisRightConfig}
        yAxisLeftConfig={yAxisLeftConfig}
        onYAxisLeftConfigChanged={setYAxisLeftConfig}
        onYAxisRightConfigChanged={setYAxisRightConfig}
        onGranularityChanged={handleGranularityChange}
        granularity={granularity}
        minDate={minDate}
      />
      <Divider sx={{ mt: 4, mb: 2 }} />
      <ComparePerformanceTable
        isLoading={isImpactLoading || isImpactFetching}
        updateImpactsMappedForTable={updateImpactsMappedForTable}
        handleOpenUpdateClicked={handleOpenUpdateClicked}
        subgenres={subgenresMap}
      />
    </>
  )
}

export default PerformanceTabView
