import { TimelineGroup, TimelineItem } from 'react-calendar-timeline'

import { TagGroup } from '../../../api/core'
import { GameTopIAPsByDate } from './GameTopIAPsByDate'
import { GameVersion } from './GameVersion'
import { LiveEventsTag } from './LiveEventsTag'
import { PerformanceEffect, PerformanceEffectType } from './PerformanceEffect'
import { TrackedGame } from './TrackedGame'
import { LiveEventReview, TrackingEvent } from './TrackingEvents'

export enum LiveEventsCalendarTimelineType {
  TrackingEvent = 'TrackingEvent',
  TrackingEventWithGame = 'TrackingEventWithGame',
  PerformanceEffect = 'PerformanceEffect',
  GameVersion = 'GameVersion',
  TopIAP = 'TopIAP',
  AnalystReview = 'AnalystReview',
  AverageRevenuePerDailyActiveUser = 'AverageRevenuePerDailyActiveUser',
}

// timeline groups
export type CustomTimelineGroup<T extends { type: LiveEventsCalendarTimelineType }> = TimelineGroup<T>
export type LiveEventsCalendarTimelineGroup =
  | TrackingEventTimelineGroup
  | TrackingEventWithGameTimelineGroup
  | PerformanceEffectTimelineGroup
  | GameVersionTimelineGroup
export type TrackingEventTimelineGroup = CustomTimelineGroup<{
  trackedGame: TrackedGame
  type: LiveEventsCalendarTimelineType
  order: number
  colorHex: string
  tagGroupId: string
}>
export type TrackingEventWithGameTimelineGroup = CustomTimelineGroup<{
  trackedGame: TrackedGame
  groupIndex: number
  firstOfGroup?: boolean
  lastOfGroup?: boolean
  type: LiveEventsCalendarTimelineType
  order: number
  colorHex: string
  tagGroupId: string
  trackingEventType: string
  trackedGamesOfEventType?: TrackedGame[]
}>

export type CommonGameDataTimeLineGroup = CustomTimelineGroup<{ type: LiveEventsCalendarTimelineType; helpLink?: string }>
export type PerformanceEffectTimelineGroup = CommonGameDataTimeLineGroup
export type GameVersionTimelineGroup = CommonGameDataTimeLineGroup
export type TopIAPTimelineGroup = CommonGameDataTimeLineGroup
export type AnalystReviewTimelineGroup = CommonGameDataTimeLineGroup

// timeline items
export type CustomTimelineItem<T extends { type: LiveEventsCalendarTimelineType }> = TimelineItem<T, number>
export type TrackingEventTimelineItem = CustomTimelineItem<{
  trackingEventId: string
  trackedGame: TrackedGame
  trackingEvent: TrackingEvent
  type: LiveEventsCalendarTimelineType
  highlighted: boolean
  new: boolean
  tagGroup?: TagGroup
  secondaryTags?: LiveEventsTag[]
}>
export type PerformanceEffectTimelineItem = CustomTimelineItem<{
  performanceEffect: PerformanceEffect
  type: LiveEventsCalendarTimelineType
  trackedGame: TrackedGame
  group: PerformanceEffectType
}>
export type GameVersionTimelineItem = CustomTimelineItem<{ version: GameVersion; type: LiveEventsCalendarTimelineType }>
export type TopIAPTimelineItem = CustomTimelineItem<{ gameTopIAPByDate: GameTopIAPsByDate; type: LiveEventsCalendarTimelineType }>
export type AnalystReviewTimelineItem = CustomTimelineItem<{ review: LiveEventReview; type: LiveEventsCalendarTimelineType }>
export type AverageRevenuePerDailyActiveUserTimelineItem = CustomTimelineItem<{ value: number; type: LiveEventsCalendarTimelineType }>

export type LiveEventsCalendarTimeLineItem =
  | TrackingEventTimelineItem
  | PerformanceEffectTimelineItem
  | GameVersionTimelineItem
  | TopIAPTimelineItem
  | AnalystReviewTimelineItem
  | AverageRevenuePerDailyActiveUserTimelineItem
