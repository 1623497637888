import { FC, forwardRef, useEffect, useRef } from 'react'
import { ReactCalendarGroupRendererProps } from 'react-calendar-timeline'

import { Help } from '@mui/icons-material'
import { Grid, IconButton, Link } from '@mui/material'

import { LiveEventTagGroup } from '../../../types/LiveEventTagGroup'
import { CommonGameDataTimeLineGroup, LiveEventsCalendarTimelineGroup, LiveEventsCalendarTimelineType } from '../../../types/LiveEvents'
import { LiveEventsCalendarTrackingEventGroup } from './LiveEventsCalendarTrackingEventGroup/LiveEventsCalendarTrackingEventGroup'
import { LiveEventsCalendarTrackingEventWithGameGroup } from './LiveEventsCalendarTrackingEventWithGameGroup/LiveEventsCalendarTrackingEventWithGameGroup'

/**
 * Component for rendering a Live Events Calendar group by its type
 */
export const LiveEventsCalendarGroup: FC<ReactCalendarGroupRendererProps<LiveEventsCalendarTimelineGroup> & any> = (props) => {
  const groupRoot = useRef<HTMLElement>(null)

  // this is the only way to get access to calendar group root node
  useEffect(() => {
    if (groupRoot.current) {
      groupRoot.current.parentElement?.classList.add(props.group.type)

      if (props.group.tagGroupId === LiveEventTagGroup.DEFINING_OCCURRENCES) {
        groupRoot.current.parentElement?.classList.add(`${props.group.type}--SpecialEvent`)
      }
    }
  }, [props.group])

  switch (props.group.type) {
    case LiveEventsCalendarTimelineType.TrackingEvent:
      return <LiveEventsCalendarTrackingEventGroup ref={groupRoot} {...props} />
    case LiveEventsCalendarTimelineType.TrackingEventWithGame:
      return <LiveEventsCalendarTrackingEventWithGameGroup ref={groupRoot} {...props} />
    case LiveEventsCalendarTimelineType.PerformanceEffect:
    case LiveEventsCalendarTimelineType.GameVersion:
    case LiveEventsCalendarTimelineType.TopIAP:
    case LiveEventsCalendarTimelineType.AnalystReview:
    case LiveEventsCalendarTimelineType.AverageRevenuePerDailyActiveUser:
      return <LiveEventsCalendarDefaultGroup ref={groupRoot} {...props} />
    default:
      return null
  }
}

/**
 * Component that renders a calendar group using only its title
 */
type LiveEventsCalendarDefaultGroupProps = ReactCalendarGroupRendererProps<CommonGameDataTimeLineGroup>

const LiveEventsCalendarDefaultGroup: FC<LiveEventsCalendarDefaultGroupProps> = forwardRef<HTMLDivElement, LiveEventsCalendarDefaultGroupProps>(
  ({ group }, ref) => {
    return (
      <Grid container ref={ref} alignItems="center" justifyContent="flex-start" columnGap={0.5}>
        {group.title}
        {group.helpLink && (
          <IconButton component={Link} href={group.helpLink} target="_blank" display="inline-flex" sx={{ padding: '4px' }}>
            <Help sx={{ fontSize: 16 }} />
          </IconButton>
        )}
      </Grid>
    )
  }
)
