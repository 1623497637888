import { endOfDay, startOfDay } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import languageService from '../../../services/LanguageService'
import { useCurrentUserLanguage } from '../../account/hooks/useCurrentUserLanguage'
import { AppType } from '../../game/types/Game'
import { GameTopIAPsByDate } from '../types/GameTopIAPsByDate'
import { GameVersion } from '../types/GameVersion'
import { LiveEventCalendarAdditionalDataId } from '../types/LiveEventAdditionalCalendarData'
import {
  AnalystReviewTimelineGroup,
  AnalystReviewTimelineItem,
  AverageRevenuePerDailyActiveUserTimelineItem,
  GameVersionTimelineGroup,
  GameVersionTimelineItem,
  LiveEventsCalendarTimeLineItem,
  LiveEventsCalendarTimelineGroup,
  LiveEventsCalendarTimelineType,
  PerformanceEffectTimelineGroup,
  PerformanceEffectTimelineItem,
  TopIAPTimelineGroup,
  TopIAPTimelineItem,
  TrackingEventTimelineGroup,
  TrackingEventTimelineItem,
  TrackingEventWithGameTimelineGroup,
} from '../types/LiveEvents'
import { PerformanceEffect, PerformanceEffectType } from '../types/PerformanceEffect'
import { TrackedGame } from '../types/TrackedGame'
import { EstimateValue, FilteredTrackingEventsByGame, LiveEventReview, TrackingEvent } from '../types/TrackingEvents'
import { useLiveEventTagGroupsMapByEventTypeId } from './useLiveEventTagGroups'

/**
 * Hook that generates calendar groups and items for a game calendar
 */
type CalendarEventsByGameHookParams = {
  selectedCalendarAdditionalDataIdsMap: { [dataId: string]: boolean }
  trackedGame: TrackedGame
  trackedEvents: TrackingEvent[]
  performanceEffects: PerformanceEffect[]
  gameVersions: GameVersion[]
  gameTopIAPs: GameTopIAPsByDate[]
  analystReviews: LiveEventReview[]
  highlightedEventId?: string
  calendarTimeStart?: number
  calendarTimeEnd?: number
  performanceValues?: EstimateValue[] | undefined
}

export const useCalendarEventsByGame = ({
  selectedCalendarAdditionalDataIdsMap,
  trackedGame,
  trackedEvents,
  performanceEffects,
  gameVersions,
  gameTopIAPs,
  analystReviews,
  highlightedEventId,
  calendarTimeStart,
  calendarTimeEnd,
  performanceValues,
}: CalendarEventsByGameHookParams) => {
  const userLanguage = useCurrentUserLanguage()
  const liveEventTagGroupsMapByEventTypeId = useLiveEventTagGroupsMapByEventTypeId()
  const today = +new Date()
  const calendarTimeRangeExceedsOneMonth = 31 * 24 * 60 * 60 * 1000 < (calendarTimeEnd || today) - (calendarTimeStart || today)

  const { items, trackedEventGroupsMap } = useMemo(() => {
    // performance effects
    const performanceEffectItems: PerformanceEffectTimelineItem[] = performanceEffects
      .map((performanceEffect, index) => ({
        id: `${performanceEffect.type}-${index}`,
        group: performanceEffect.type,
        start_time: performanceEffect.startTime,
        end_time: performanceEffect.endTime,
        canMove: false,
        canResize: false,
        performanceEffect,
        type: LiveEventsCalendarTimelineType.PerformanceEffect,
        trackedGame: trackedGame,
      }))
      .filter((performanceEffect) =>
        calendarTimeRangeExceedsOneMonth
          ? performanceEffect.performanceEffect.type !== PerformanceEffectType.DAU
          : performanceEffect.performanceEffect.type !== PerformanceEffectType.MAU
      )

    // game versions
    const gameVersionItems: GameVersionTimelineItem[] = gameVersions.map((gameVersion, index) => ({
      id: `${gameVersion.gameId}-${gameVersion.version}-${index}`,
      group: LiveEventsCalendarTimelineType.GameVersion,
      start_time: gameVersion.startTime,
      end_time: gameVersion.endTime,
      canMove: false,
      canResize: false,
      version: gameVersion,
      type: LiveEventsCalendarTimelineType.GameVersion,
    }))

    // Top IAPs
    const topIAPItems: TopIAPTimelineItem[] = gameTopIAPs.map((gameTopIAPByDate, index) => {
      return {
        id: `${gameTopIAPByDate.gameId}-${index}`,
        group: LiveEventsCalendarTimelineType.TopIAP,
        start_time: gameTopIAPByDate.startTime,
        end_time: gameTopIAPByDate.endTime,
        canMove: false,
        canResize: false,
        gameTopIAPByDate: gameTopIAPByDate,
        type: LiveEventsCalendarTimelineType.TopIAP,
      }
    })

    // notes
    const reviewItems: AnalystReviewTimelineItem[] = analystReviews.map((review, index) => ({
      id: `${LiveEventsCalendarTimelineType.AnalystReview}-${review.gameId}-${index}`,
      group: LiveEventsCalendarTimelineType.AnalystReview,
      title: review.comment.content.title?.[userLanguage] || review.comment.content.title?.[languageService.defaultLanguage],
      start_time: startOfDay(review.start).getTime(),
      end_time: endOfDay(review.end).getTime(),
      canMove: false,
      canResize: false,
      review,
      type: LiveEventsCalendarTimelineType.AnalystReview,
    }))

    // tracked events
    const trackedEventGroupsMap: { [groupId: string]: number } = {}
    const highlightedEventsMap: { [eventId: string]: boolean } = {}
    const items: LiveEventsCalendarTimeLineItem[] = []

    trackedEvents.forEach((trackedEvent, index) => {
      const groupId = trackedEvent.typeId
      const eventId = trackedEvent.eventId
      let firstItemForNewHighlighting = false

      if (!trackedEventGroupsMap[groupId]) {
        trackedEventGroupsMap[groupId] = liveEventTagGroupsMapByEventTypeId[groupId]
          ? liveEventTagGroupsMapByEventTypeId[groupId].order
          : Number.MAX_SAFE_INTEGER
      }

      if (!highlightedEventsMap[eventId]) {
        highlightedEventsMap[eventId] = true
        firstItemForNewHighlighting = true
      }

      const startTime = startOfDay(new Date(trackedEvent.start))
      const endTime = endOfDay(new Date(trackedEvent.end))

      items.push({
        id: `${trackedEvent.eventId}-${index}`,
        group: groupId,
        title: trackedEvent.name,
        start_time: startTime.getTime(),
        end_time: endTime.getTime(),
        canMove: false,
        canResize: false,
        trackingEventId: trackedEvent.eventId,
        trackedGame: trackedGame,
        trackingEvent: trackedEvent,
        highlighted: highlightedEventId === trackedEvent.eventId ? true : false,
        new: trackedEvent.highlighted && firstItemForNewHighlighting ? true : false,
        type: LiveEventsCalendarTimelineType.TrackingEvent,
        tagGroup: liveEventTagGroupsMapByEventTypeId[trackedEvent.typeId],
        secondaryTags: trackedEvent.tags.map((tagId) => ({
          id: tagId,
          name: languageService.getTranslation('tags', tagId),
        })),
      })
    })

    // average revenue per daily active user
    const averageRevenuePerDailyActiveUserItems: AverageRevenuePerDailyActiveUserTimelineItem[] =
      performanceValues
        ?.filter((value) => !!value.averageRevenuePerDailyActiveUser)
        .map((revenuePerActiveUser) => {
          return {
            id: `${LiveEventsCalendarTimelineType.AverageRevenuePerDailyActiveUser}-${revenuePerActiveUser.date}`,
            group: LiveEventsCalendarTimelineType.AverageRevenuePerDailyActiveUser,
            start_time: startOfDay(revenuePerActiveUser.date).getTime(),
            end_time: endOfDay(revenuePerActiveUser.date).getTime(),
            canMove: false,
            canResize: false,
            value: revenuePerActiveUser.averageRevenuePerDailyActiveUser ? Math.round(revenuePerActiveUser.averageRevenuePerDailyActiveUser * 100) / 100 : 0,
            type: LiveEventsCalendarTimelineType.AverageRevenuePerDailyActiveUser,
          }
        }) || []

    return {
      items: [
        ...gameVersionItems,
        ...topIAPItems,
        ...performanceEffectItems,
        ...reviewItems,
        ...averageRevenuePerDailyActiveUserItems,
        ...items.sort((a, b) => ((a.title || '') < (b.title || '') ? -1 : 1)),
      ] as LiveEventsCalendarTimeLineItem[],
      trackedEventGroupsMap,
    }
  }, [
    performanceEffects,
    gameVersions,
    gameTopIAPs,
    analystReviews,
    trackedEvents,
    performanceValues,
    trackedGame,
    calendarTimeRangeExceedsOneMonth,
    userLanguage,
    highlightedEventId,
    liveEventTagGroupsMapByEventTypeId,
  ])

  const getHelpLinkByPerformanceEffectType = useHelpLinkByPerformanceEffectType()

  const groups = useMemo(() => {
    // performance effects
    const performanceEffectGroups = Object.values(PerformanceEffectType).reduce((acc, performanceEffectType) => {
      if (
        (selectedCalendarAdditionalDataIdsMap[LiveEventCalendarAdditionalDataId.DownloadChange] && performanceEffectType === PerformanceEffectType.Download) ||
        (selectedCalendarAdditionalDataIdsMap[LiveEventCalendarAdditionalDataId.RevenueChange] && performanceEffectType === PerformanceEffectType.Revenue) ||
        (selectedCalendarAdditionalDataIdsMap[LiveEventCalendarAdditionalDataId.AUChange] &&
          performanceEffectType === PerformanceEffectType.DAU &&
          !calendarTimeRangeExceedsOneMonth) ||
        (selectedCalendarAdditionalDataIdsMap[LiveEventCalendarAdditionalDataId.AUChange] &&
          performanceEffectType === PerformanceEffectType.MAU &&
          calendarTimeRangeExceedsOneMonth)
      ) {
        acc[performanceEffectType] = {
          id: performanceEffectType,
          title: <Trans i18nKey={`live-events:performance_effect_type_${performanceEffectType.toLowerCase()}`} />,
          type: LiveEventsCalendarTimelineType.PerformanceEffect,
          helpLink: getHelpLinkByPerformanceEffectType(performanceEffectType),
        }
      }

      return acc
    }, {} as { [groupId in PerformanceEffectType]: PerformanceEffectTimelineGroup })

    // game versions
    const gameVersionGroups: GameVersionTimelineGroup[] = selectedCalendarAdditionalDataIdsMap[LiveEventCalendarAdditionalDataId.GameVersion]
      ? [
          {
            id: LiveEventsCalendarTimelineType.GameVersion,
            title: <Trans i18nKey={`live-events:game_versions_timeline_group`} />,
            type: LiveEventsCalendarTimelineType.GameVersion,
            stackItems: true,
          },
        ]
      : []

    // Top IAPs
    const topIAPGroups: TopIAPTimelineGroup[] = selectedCalendarAdditionalDataIdsMap[LiveEventCalendarAdditionalDataId.TopIAPs]
      ? [
          {
            id: LiveEventsCalendarTimelineType.TopIAP,
            title: <Trans i18nKey={`live-events:top_iaps_calendar_group_name`} />,
            type: LiveEventsCalendarTimelineType.TopIAP,
            stackItems: true,
          },
        ]
      : []

    // notes
    const reviewGroups: AnalystReviewTimelineGroup[] = selectedCalendarAdditionalDataIdsMap[LiveEventCalendarAdditionalDataId.AnalystReview]
      ? [
          {
            id: LiveEventsCalendarTimelineType.AnalystReview,
            title: <Trans i18nKey="live-events:analyst_review_timeline_group" />,
            type: LiveEventsCalendarTimelineType.AnalystReview,
            stackItems: true,
          },
        ]
      : []

    // average revenue per daily active user
    const averageRevenuePerDailyActiveUserGroups: PerformanceEffectTimelineGroup[] = selectedCalendarAdditionalDataIdsMap[
      LiveEventCalendarAdditionalDataId.AverageRevenuePerDailyActiveUser
    ]
      ? [
          {
            id: LiveEventsCalendarTimelineType.AverageRevenuePerDailyActiveUser,
            title: <Trans i18nKey="live-events:average_revenue_per_daily_active_user" />,
            type: LiveEventsCalendarTimelineType.AverageRevenuePerDailyActiveUser,
            stackItems: false,
          },
        ]
      : []

    // tracked events
    const eventGroups: TrackingEventTimelineGroup[] = []
    Object.keys(trackedEventGroupsMap).forEach((groupId) => {
      eventGroups.push({
        id: groupId,
        title: languageService.getTranslation('tags', groupId),
        stackItems: true,
        trackedGame,
        type: LiveEventsCalendarTimelineType.TrackingEvent,
        order: liveEventTagGroupsMapByEventTypeId[groupId] ? liveEventTagGroupsMapByEventTypeId[groupId].order : Number.MAX_SAFE_INTEGER,
        colorHex: liveEventTagGroupsMapByEventTypeId[groupId] ? liveEventTagGroupsMapByEventTypeId[groupId].colorHex : '',
        tagGroupId: liveEventTagGroupsMapByEventTypeId[groupId] ? liveEventTagGroupsMapByEventTypeId[groupId].id : '',
      })
    })

    let dataGroups: LiveEventsCalendarTimelineGroup[] = []

    // Hide some data groups from PC/Console Games as there is no data for those groups
    if (trackedGame.game.appType === AppType.PC_CONSOLE) {
      dataGroups = [...dataGroups, ...reviewGroups] as LiveEventsCalendarTimelineGroup[]
    } else {
      dataGroups = [
        ...dataGroups,
        ...gameVersionGroups,
        ...topIAPGroups,
        ...Object.values(performanceEffectGroups),
        ...averageRevenuePerDailyActiveUserGroups,
        ...reviewGroups,
      ] as LiveEventsCalendarTimelineGroup[]
    }

    return [
      ...dataGroups,
      ...eventGroups.sort((a, b) => {
        if (a.order === b.order) {
          return (
            ((a.title as string) || '').localeCompare((b.title as string) || '') ||
            a.trackedGame?.game?.resolvedName?.localeCompare(b.trackedGame?.game?.resolvedName || '') ||
            0
          )
        } else {
          return a.order < b.order ? -1 : 1
        }
      }),
    ] as LiveEventsCalendarTimelineGroup[]
  }, [
    calendarTimeRangeExceedsOneMonth,
    getHelpLinkByPerformanceEffectType,
    liveEventTagGroupsMapByEventTypeId,
    selectedCalendarAdditionalDataIdsMap,
    trackedEventGroupsMap,
    trackedGame,
  ])

  return { items, groups }
}

/**
 * Hook that generates calendar groups and items for event comparison calendar
 */
type CalendarEventsByEventTypeHookParams = {
  trackingEventsByGame: FilteredTrackingEventsByGame
  trackedGames: TrackedGame[]
  highlightedEventId?: string
  visibleGroups?: string[]
}

export const useCalendarEventsByEventType = ({
  trackingEventsByGame,
  trackedGames,
  highlightedEventId,
  visibleGroups,
}: CalendarEventsByEventTypeHookParams) => {
  const liveEventTagGroupsMapByEventTypeId = useLiveEventTagGroupsMapByEventTypeId()

  const groupedItems = useMemo(() => {
    const highlightedEventsMap: { [eventId: string]: boolean } = {}
    const groupedItems = Object.values(trackingEventsByGame.events)
      .flatMap((trackingEvents) => trackingEvents)
      .reduce((acc, trackedEvent, index) => {
        const startTime = startOfDay(new Date(trackedEvent.start))
        const endTime = endOfDay(new Date(trackedEvent.end))
        const groupId = `${trackedEvent.typeId}_${trackedEvent.gameId}`
        const eventId = `${trackedEvent.eventId}_${trackedEvent.gameId}`
        let firstItemForNewHighlighting = false
        const trackedGame = trackedGames.find((trackedGame) => trackedGame.game.id === trackedEvent.gameId)

        if (!trackedGame) {
          return acc
        }

        if (!highlightedEventsMap[eventId]) {
          highlightedEventsMap[eventId] = true
          firstItemForNewHighlighting = true
        }

        const item: TrackingEventTimelineItem = {
          id: `${groupId}_${trackedEvent.eventId}_${index}`,
          group: groupId,
          title: trackedEvent.name,
          start_time: startTime.getTime(),
          end_time: endTime.getTime(),
          canMove: false,
          canResize: false,
          trackingEventId: trackedEvent.eventId,
          trackedGame: trackedGame as TrackedGame,
          trackingEvent: trackedEvent,
          highlighted: highlightedEventId === trackedEvent.eventId ? true : false,
          new: trackedEvent.highlighted && firstItemForNewHighlighting ? true : false,
          type: LiveEventsCalendarTimelineType.TrackingEvent,
          tagGroup: liveEventTagGroupsMapByEventTypeId[trackedEvent.typeId],
        }

        if (acc[groupId]) {
          acc[groupId] = [...acc[groupId], item]
        } else {
          acc[groupId] = [item]
        }

        return acc
      }, {} as { [groupId: string]: TrackingEventTimelineItem[] })

    return groupedItems
  }, [trackingEventsByGame.events, trackedGames, highlightedEventId, liveEventTagGroupsMapByEventTypeId])

  const sortedGroups = useMemo(() => {
    const groups: TrackingEventWithGameTimelineGroup[] = []
    Object.entries(groupedItems).forEach(([groupId, items]) => {
      const typeId = groupId.split('_')[0]
      groups.push({
        id: groupId,
        groupIndex: 0,
        title: languageService.getTranslation('tags', typeId),
        stackItems: true,
        trackedGame: items[0].trackedGame as TrackedGame,
        type: LiveEventsCalendarTimelineType.TrackingEventWithGame,
        order: liveEventTagGroupsMapByEventTypeId[typeId] ? liveEventTagGroupsMapByEventTypeId[typeId].order : Number.MAX_SAFE_INTEGER,
        colorHex: liveEventTagGroupsMapByEventTypeId[typeId] ? liveEventTagGroupsMapByEventTypeId[typeId].colorHex : '',
        tagGroupId: liveEventTagGroupsMapByEventTypeId[typeId] ? liveEventTagGroupsMapByEventTypeId[typeId].id : '',
        trackingEventType: typeId,
      })
    })

    const gamesByEventType = groups.reduce((acc, group) => {
      const currentValue = acc[group.trackingEventType]
      if (currentValue && group.trackedGame) {
        acc[group.trackingEventType] = [...currentValue, group.trackedGame]
      } else {
        acc[group.trackingEventType] = group.trackedGame ? [group.trackedGame] : []
      }
      return acc
    }, {} as { [eventTypeId: string]: TrackedGame[] })

    let groupIndex = 0
    const sortedGroups = groups
      .sort((a, b) => {
        if (a.order === b.order) {
          return (
            ((a.title as string) || '').localeCompare((b.title as string) || '') ||
            a.trackedGame?.game?.resolvedName?.localeCompare(b.trackedGame?.game?.resolvedName || '') ||
            0
          )
        } else {
          return a.order < b.order ? -1 : 1
        }
      })
      .map((group, index, array) => {
        const previousIndex = index === 0 ? index : index - 1
        const nextIndex = index + 1
        const previousGroup = array[previousIndex]
        const nextGroup = array.length <= nextIndex ? group : array[nextIndex]
        if (index === 0 || group.title !== previousGroup.title) {
          groupIndex++
          return {
            ...group,
            groupIndex,
            firstOfGroup: true,
            lastOfGroup: nextGroup.title !== group.title,
            trackedGamesOfEventType: gamesByEventType[group.trackingEventType],
          }
        } else {
          return {
            ...group,
            groupIndex,
            firstOfGroup: false,
            lastOfGroup: nextGroup.title !== group.title,
            trackedGamesOfEventType: gamesByEventType[group.trackingEventType],
          }
        }
      })
      .filter((group) => (visibleGroups?.includes(group.trackingEventType) ? true : group.firstOfGroup))

    return sortedGroups
  }, [groupedItems, liveEventTagGroupsMapByEventTypeId, visibleGroups])

  const items = useMemo(() => {
    return Object.entries(groupedItems)
      .filter(([groupId, items]) => {
        const matchedGroup = sortedGroups.find((group) => group.id === groupId)
        const visible = visibleGroups?.includes(matchedGroup?.trackingEventType || '')
        return visible
      })
      .flatMap(([groupId, items]) => items)

      .sort((a, b) => ((a.title as string) || '').localeCompare((b.title as string) || ''))
  }, [groupedItems, sortedGroups, visibleGroups])

  return {
    groups: sortedGroups,
    items,
  }
}

/**
 * Resolves help link url for different performance effect types
 */
export const useHelpLinkByPerformanceEffectType = () => {
  const { t } = useTranslation()

  return useCallback(
    (performanceEffectType: PerformanceEffectType) => {
      switch (performanceEffectType) {
        case PerformanceEffectType.DAU:
        case PerformanceEffectType.MAU:
          return t('common:activeUsersDocUrl')
        default:
          return
      }
    },
    [t]
  )
}
