import { FC } from 'react'
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline'

import { LiveEventsCalendarTimeLineItem, LiveEventsCalendarTimelineType } from '../../../types/LiveEvents'
import { LiveEventsCalendarAnalystReviewItem } from './LiveEventsCalendarAnalystReviewItem/LivEventsCalendarAnalystReviewItem'
import { LiveEventsCalendarAverageRevenuePerDailyActiveUser } from './LiveEventsCalendarAverageRevenuePerDailyActiveUser/LiveEventsCalendarAverageRevenuePerDailyActiveUser'
import { LiveEventsCalendarGameVersionItem } from './LiveEventsCalendarGameVersionItem/LiveEventsCalendarGameVersionItem'
import { LiveEventsCalendarPerformanceEffectItem } from './LiveEventsCalendarPerformanceEffectItem/LiveEventsCalendarPerformanceEffectItem'
import { LiveEventsCalendarTopIAPItem } from './LiveEventsCalendarTopIAPItem/LiveEventsCalendarTopIAPItem'
import { LiveEventsCalendarTrackingEventItem } from './LiveEventsCalendarTrackingEventItem/LiveEventsCalendarTrackingEventItem'

export const LiveEventsCalendarItem: FC<ReactCalendarItemRendererProps<LiveEventsCalendarTimeLineItem> & any> = (props) => {
  switch (props.item.type) {
    case LiveEventsCalendarTimelineType.TrackingEvent:
      return <LiveEventsCalendarTrackingEventItem {...props} />
    case LiveEventsCalendarTimelineType.PerformanceEffect:
      return <LiveEventsCalendarPerformanceEffectItem {...props} />
    case LiveEventsCalendarTimelineType.GameVersion:
      return <LiveEventsCalendarGameVersionItem {...props} />
    case LiveEventsCalendarTimelineType.TopIAP:
      return <LiveEventsCalendarTopIAPItem {...props} />
    case LiveEventsCalendarTimelineType.AnalystReview:
      return <LiveEventsCalendarAnalystReviewItem {...props} />
    case LiveEventsCalendarTimelineType.AverageRevenuePerDailyActiveUser:
      return <LiveEventsCalendarAverageRevenuePerDailyActiveUser {...props} />
    default:
      return null
  }
}
